<template>
  <div class="contain">
    <div class="modalityTop">
      <el-form
        ref="search"
        class="searchForm"
        :inline="true"
        :model="paramsData"
      >
        <el-form-item label="时间段:">
          <!-- @change="search" -->
          <el-date-picker
            
            style="width: 250px"
            v-model="DataPicker"
            type="daterange"
            size="mini"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <!-- 事件类型: -->
        <el-form-item label="事件类型">
          <el-cascader
            size="mini"
            style="width: 170px"
            :options="eventTypeList"
            v-model="eventTypeIdList"
            :props="{ label: 'name', value: 'id', multiple: true }"
            separator=">"
            clearable
            collapse-tags
            @change="deformation"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="事件状态">
          <!-- 事件状态: -->
          <!-- @change="search" -->
          <el-select
            clearable
            
            style="width: 170px"
            size="mini"
            v-model="paramsData.eventStatus"
            placeholder="请选择状态"
          >
            <el-option
              v-for="item in userStatus"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡检点状态">
          <!-- 巡检点状态: -->
          <!-- @change="search" -->
          <el-select
            clearable
            
            size="mini"
            style="width: 170px"
            v-model="paramsData.checkpoint"
            placeholder="请选择状态"
          >
            <el-option
              v-for="item in sourceStatus"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡线员">
          <!-- 巡线员: -->
          <!-- @change="search" -->
          <el-select
            clearable
            
            size="mini"
            style="width: 170px"
            v-model="paramsData.eventPeopleIds"
            placeholder="请选择巡线员"
          >
            <el-option
              v-for="item in peopleList"
              :key="item.id"
              :label="item.username"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字">
          <!-- 关键字: -->
          <el-input
            clearable
            size="mini"
            placeholder="请输入创建人/地址"
            v-model="paramsData.searchText"
            style="width: 250px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="" label-width="0">
          <el-button type="primary" @click="search" size="mini">查询</el-button>
          <el-button
            @click="resize"
            class="EquipmentMarginLeft"
            size="mini"
            type="warning"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="modalityCenter">
      <el-button
        size="mini"
        type="text"
        style="
          float: right;
          border: 1px solid #1082ff;
          color: #1082ff;
          padding: 0 15px;
          height: 30px;
          margin: 0 0px 10px;
        "
        class="addButton"
        v-if="$anthButtons.getButtonAuth('addEvent')"
        @click="addGroup"
        >添加</el-button
      >
      <el-table
        border
        :data="tableData"
        style="width: 100%"
        height="calc(100vh - 398px)"
        :row-class-name="tableRowClassName"
        :header-cell-style="{
          color: '#333',
          fontFamily: 'MicrosoftYaHeiUI',
          fontSize: '14px',
          fontWeight: 900,
          textAlign: 'center',
          background: '#f8f8f9',
        }"
      >
        <el-table-column
          type="index"
          label="序号"
          align="left"
          width="80"
        ></el-table-column>
        <el-table-column property="eventCodeName" label="事件类型" align="left">
          <template slot-scope="{ row }">
            <div :style="{ color: row.isRead == 2 ? 'red' : '#0C235B' }">
              {{
                row.eventCodeIdConfirmName
                  ? row.eventCodeIdConfirmName
                  : row.eventCodeName
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          property="eventResultsName"
          label="事件状态"
          align="center"
          width="90"
        >
          <template slot-scope="{ row }">
            <el-tag v-if="row.eventResults == 0" type="warning" size="mini">{{
              row.eventResultsName
            }}</el-tag>
            <el-tag v-if="row.eventResults == 1" type="success" size="mini">{{
              row.eventResultsName
            }}</el-tag>
            <el-tag v-if="row.eventResults == 2" type="warning" size="mini">{{
              row.eventResultsName
            }}</el-tag>
            <el-tag v-if="row.eventResults == 3" size="mini">{{
              row.eventResultsName
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          property="eventAddressConfirm"
          label="地址"
          show-overflow-tooltip
          align="left"
        >
        <template slot-scope="{ row }">
          {{row.eventResults == 0 ? row.eventAddress : row.eventAddressConfirm}}
          </template>
        </el-table-column>
        <el-table-column
          property="createBy"
          label="创建人"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column property="eventPeopleName" label="巡线员" align="left">
          <template slot-scope="{ row }">
            {{
              row.eventResults == 1 && !row.eventPeopleName
                ? "--"
                : row.eventPeopleName
            }}
          </template>
        </el-table-column>
        <el-table-column
          property="createTime"
          label="提交时间"
          align="left"
        ></el-table-column>
        <el-table-column
          property="checkingPoint"
          label="关联工单"
          align="center"
          width="90"
        >
          <template slot-scope="{ row }">
            <div
              @click="details(row.dataType, row.tplId, row)"
              v-if="row.eventDataId"
              style="color: #1082ff; cursor: pointer"
            >
              有
            </div>
            <div v-else type="info">无</div>
          </template>
        </el-table-column>
        <el-table-column
          property="checkingPoint"
          label="巡检点"
          align="center"
          width="90"
        >
          <template slot-scope="{ row }">
            <div v-if="row.checkingPoint == 0" type="info">否</div>
            <div v-if="row.checkingPoint == 1" type="success">是</div>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="State" label="操作" width="170">
          <template slot-scope="{ row }">
            <el-button
              type="primary"
              @click="seeDetil(row)"
              v-if="$anthButtons.getButtonAuth('lookEvent')"
              size="mini"
              >详情</el-button
            >
            <el-button
              v-if="
                row.eventResults == 0 &&
                $anthButtons.getButtonAuth('deleteEvent')
              "
              type="danger"
              @click="delhandle(row)"
              size="mini"
              >删除</el-button
            >
            <el-dropdown
              @command="(command) => exchange(command, row)"
              v-if="row.eventResults != 0"
            >
              <el-button
                type="text"
                size="mini"
                style="
                  border: 1px solid #1082ff;
                  color: #1082ff;
                  padding: 0 15px;
                  height: 30px;
                  margin: 0 11px;
                "
                >更多</el-button
              >
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="设置巡检点"
                  v-if="
                    row.eventResults != 0 &&
                    row.checkingPoint == 0 &&
                    $anthButtons.getButtonAuth('setEvent')
                  "
                  >设置巡检点</el-dropdown-item
                >
                <el-dropdown-item
                  command="取消巡检点"
                  v-if="
                    row.eventResults != 0 &&
                    row.checkingPoint == 1 &&
                    $anthButtons.getButtonAuth('cancelEvent')
                  "
                  >取消巡检点</el-dropdown-item
                >
                <el-dropdown-item
                  command="转维修"
                  disabled
                  v-if="
                    row.eventResults == 1 &&
                    row.isMaintain == 1 &&
                    $anthButtons.getButtonAuth('turnEvent')
                  "
                  
                  >转维修</el-dropdown-item
                >
                <!-- @click="maintain(row)" -->
               
                <el-dropdown-item
                  command="设为已处理"
                   v-if="
                    (row.eventResults == 1 || row.eventResults == 2) &&
                    $anthButtons.getButtonAuth('disposeEvent')"
                  >设为已处理</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>

      <div style="text-align: left">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="paramsData.current"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="paramsData.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="eventstotal"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 事件详情 -->
    <el-dialog width="40%" title="详情" :visible.sync="detilVisible">
      <!-- 平台添加的待确认的事件详情 -->
      <!-- eventResults	事件状态：0：待确认，1：已确认，2：已关闭 -->
      <el-descriptions
        title=""
        :column="1"
        :labelStyle="{ width: '80px' }"
        v-if="!detiltable.taskId"
      >
        <el-descriptions-item label="事件类型">{{
          detiltable.eventCodeName
        }}</el-descriptions-item>
        <el-descriptions-item label="地址">{{
          detiltable.eventAddress
        }}</el-descriptions-item>
        <el-descriptions-item label="巡线员">{{
          detiltable.eventPeopleName
        }}</el-descriptions-item>
        <el-descriptions-item label="上报人电话">{{
          detiltable.eventPlone
        }}</el-descriptions-item>
        <el-descriptions-item label="备注">{{
          detiltable.eventRemark
        }}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{
          detiltable.createTime
        }}</el-descriptions-item>
      </el-descriptions>
      <!-- 巡线员已确认的事件详情 -->
      <span v-if="detiltable.eventCodeIdConfirmName && !detiltable.taskId">
        <el-divider v-if="detiltable.eventSource != 2"></el-divider>
        <el-descriptions title="" :column="1" :labelStyle="{ width: '80px' }">
          <el-descriptions-item label="事件类型">{{
            detiltable.eventCodeIdConfirmName
          }}</el-descriptions-item>
          <el-descriptions-item label="详细地址">{{
            detiltable.eventAddressConfirm
          }}</el-descriptions-item>
          <el-descriptions-item
            label="巡线员"
            v-if="detiltable.eventSource == 1"
            >{{ detiltable.eventPeopleName }}</el-descriptions-item
          >
          <el-descriptions-item label="说明">{{
            detiltable.eventDescribe
          }}</el-descriptions-item>
          <el-descriptions-item label="图片">
            <div
              v-for="(item, index) in detiltable.imgUrlArr"
              :key="index"
              style="float: left; margin-left: 10px; border: 1px solid #ecedf1"
            >
              <el-image
                style="width: 100px; height: 100px"
                :src="item"
                :preview-src-list="detiltable.imgUrlArr"
              >
              </el-image>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="附件" v-if="detiltable.pid == 112">
            <span
              v-for="(item, i) in detiltable.fileUrlArr"
              :key="i + 'file34'"
            >
              <el-tooltip
                class="item"
                effect="light"
                :content="item"
                placement="top"
              >
                <el-link :underline="false" :href="item" target="_blank"><span class="content-text">{{ item }}</span></el-link>
              </el-tooltip>
            </span>
          </el-descriptions-item>
          <el-descriptions-item label="提交时间">{{
            detiltable.confirmTime
          }}</el-descriptions-item>
        </el-descriptions>
      </span>
      <!-- 巡线员巡检过程中提交的事件详情 -->
      <div v-if="detiltable.taskId">
        <el-descriptions title="" :column="1" :labelStyle="{ width: '80px' }">
          <el-descriptions-item label="事件类型">{{
            detiltable.eventCodeIdConfirmName
          }}</el-descriptions-item>
          <el-descriptions-item label="详细地址">{{
            detiltable.eventAddressConfirm
          }}</el-descriptions-item>
          <el-descriptions-item label="巡线员">{{
            detiltable.eventPeopleName
          }}</el-descriptions-item>
          <el-descriptions-item label="说明">{{
            detiltable.eventDescribe
          }}</el-descriptions-item>
          <el-descriptions-item label="图片">
            <div
              v-for="(item, index) in detiltable.imgUrlArr"
              :key="index"
              style="float: left; margin-left: 10px; border: 1px solid #ecedf1"
            >
              <el-image
                style="width: 100px; height: 100px"
                :src="item"
                :preview-src-list="detiltable.imgUrlArr"
              >
              </el-image>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="附件" v-if="detiltable.pid == 112">
            <span
              v-for="(item, i) in detiltable.fileUrlArr"
              :key="i + 'file34'"
            >
              <el-tooltip
                class="item"
                effect="light"
                :content="item"
                placement="top"
              >
                <el-link :href="item" target="_blank"><span class="content-text">{{ item }}</span></el-link>
              </el-tooltip>
            </span>
          </el-descriptions-item>
          <el-descriptions-item label="提交时间">{{
            detiltable.confirmTime
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <!-- 设为已处理 -->
      <div v-if="detiltable.eventResults == 3">
        <el-descriptions title="" :column="1" :labelStyle="{ width: '80px' }">
          <el-descriptions-item label="已处理人">{{
            detiltable.handler
          }}</el-descriptions-item>
          <el-descriptions-item label="已处理时间">{{
            detiltable.handleTime
          }}</el-descriptions-item>
        <el-descriptions-item label="已处理备注">{{
            detiltable.handleRemark
          }}</el-descriptions-item>
          <el-descriptions-item label="已处理图片" v-if="detiltable.handleImgUrl">
            <div
              v-for="(item, index) in detiltable.handleImgUrl.split(',')"
              :key="index"
              style="float: left; margin-left: 10px; border: 1px solid #ecedf1"
            >
              <el-image
                style="width: 100px; height: 100px"
                :src="item"
                :preview-src-list="detiltable.handleImgUrl.split(',')"
              >
              </el-image>
            </div>
          </el-descriptions-item>
          </el-descriptions>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detilVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 添加事件 -->
    <el-dialog
      title="添加事件"
      width="25%"
      :visible.sync="showAddModel"
      :show-close="false"
      :close-on-click-modal="false"
      @close="closeeventDialog('form')"
    >
      <el-form ref="form" :model="form" label-width="120px" :rules="rule">
        <el-form-item label="事件类型：" prop="eventCodeName">
          <el-cascader
            style="width: 100%"
            :options="eventTypeList"
            v-model="form.eventCodeName"
            :props="{ label: 'name', value: 'id' }"
            separator=">"
            clearable
            collapse-tags
            @change="eventCodeSelect"
          >
          </el-cascader>
        </el-form-item>
        <!-- v-if="form.eventAddress" -->
        <el-form-item
          label="地址："
          
          prop="eventAddress"
          label-width="120px"
        >
          <el-input v-model="form.eventAddress" placeholder="请选择地址">
            <i
              class="el-icon-location"
              style="color: #1082ff; font-size: 18px; margin-right: 10px"
              slot="suffix"
              @click="showModel = true"
            >
            </i>
          </el-input>
        </el-form-item>
        <!-- <el-form-item
          label="地址："
          v-if="!form.eventAddress"
          prop="eventAddress"
          label-width="120px"
        >
          <el-input
            v-model="form.eventAddress"
            placeholder="请选择地址"
            @focus="showModel = true"
          >
            <i
              class="el-icon-location"
              style="color: #1082ff; font-size: 18px; margin-right: 10px"
              slot="suffix"
            >
            </i>
          </el-input>
        </el-form-item> -->
        <!-- <el-form-item label="事件状态："  prop="eventResults">
          <el-radio  v-model="form.eventResults" label="0">待确认</el-radio>
          <el-radio  v-model="form.eventResults" label="1">已确认</el-radio>
        </el-form-item> -->
        <el-form-item label="巡线员：" prop="lineAttendant" label-width="120px">
          <el-select
            clearable
            style="width: 100%"
            value-key="userId"
            v-model="form.lineAttendant"
            placeholder="报送人"
          >
            <el-option
              v-for="item in peopleList"
              :key="item.id"
              :label="item.username + '(' + item.mobile + ')'"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="上报人电话："
           prop="eventPlone"
          label-width="120px"
        >
          <el-input
            v-model="form.eventPlone"
            placeholder="请输入上报人电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注：" label-width="120px">
          <el-input
            v-model="form.eventRemark"
            type="textarea"
            name="limitLength"
            maxlength="200"
            placeholder="请输入备注"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddModel = false">取 消</el-button>
        <el-button type="primary" @click="submitAdd('form')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 选择位置 -->
    <el-dialog
      :visible.sync="showModel"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <mapDialog @closeModal="closeModel"></mapDialog>
    </el-dialog>
    <!-- 选择维修人员 -->
    <el-dialog
      title="提交维修工单"
      :visible.sync="showeventDialog"
      :show-close="true"
      width="25%"
      :close-on-click-modal="false"
    >
      <el-form ref="texture" :model="texture" label-width="120px" :rules="rule">
        <el-form-item label="受理部门：" prop="deptId">
          <el-cascader
                v-model="texture.deptId"
                :options="sectionList"
                :props="TreeProps"
                size="mini"
                style="width: 100%"
                :show-all-levels="false"
                ref="cascaderHandle"
                @change="selectDepartment"
            >
              <span slot-scope="{data}">
                  {{data.name}}
              </span>
            </el-cascader>
        </el-form-item>
        <el-form-item label="受理人：" >
          <el-select
            clearable
            size="mini"
            filterable
            style="width: 100%"
            v-model="texture.userId"
            placeholder="请选择受理人"
          >
            <el-option
              v-for="item in maintainUserList"
              :key="item.id"
              :label="item.realname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="优先级：" prop="priorLevel">
          <el-radio-group v-model="texture.priorLevel">
            <el-radio :label="item.id" v-for="(item,index) in priorityList" :key="index">{{item.priorLevel}}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showeventDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitService('form')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 设为已处理 -->
    <el-dialog
      title="设为已处理"
      :visible.sync="showprocessed"
      width="25%"
      :close-on-click-modal="false"
      @close="closeshowprocessed('processedform')"
    >
    <el-form ref="processedform" :model="processedform" label-width="90px" :rules="rule">
      <el-form-item label="图片:">
        <uploadfile :fileData="{}" ref="uploadfile"></uploadfile>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            v-model="processedform.handleRemark"
            type="textarea"
            maxlength="200"
            name="limitLength"
            placeholder="请输入备注"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showprocessed = false">取 消</el-button>
        <el-button type="primary" @click="submitprocessed('processedform')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  ByPostpeople,
  closeevent,
  deleteBatchevent,
  dicByType,
  eventPage,
  zypatrolGetUserList,
  patrolGetDict,
  setEvent,
  eventPagedetil,
  saveevent,
  excelEvents,
  setcheckpoint,
  uploadImg,
  markEvent,
  saveMaintain,
  maintainEvent,
  selectAllUsersByDeptId,
  getTimeoutList,
  eventMaintain
} from "@/RequestPort/maintenance";
import {
  selectSysDeptTreeList
} from '../../RequestPort/user/user'
import mapDialog from "./mapDialog";
import { geoOws } from "@/apis/geo";
import uploadfile from '@/components/uploadFile.vue'
export default {
  name: "index",
  components: { mapDialog,uploadfile },
  data() {
    return {
      TreeProps:{
        label: 'name',
        value:'id',
        children:'children',
        emitPath:false,
        checkStrictly: true
      },
      // 选择位置弹窗
      showModel: false,
      // 详情弹窗
      detilVisible: false,
      // 添加事件弹窗
      showAddModel: false,
      // 维修工单弹窗
      showeventDialog: false,
      // 查询条件
      paramsData: {
        current: 1,
        size: 100,
      },
      // 级联的下一选择
      cascade: true,
      // 级联的下一级
      array: [],
      // 新增事件
      form: {
        eventCodeName: [],
        eventAddress: "",
        lineAttendant:'',
        eventPlone:'',
        eventRemark:''
        // lineAttendant:[]
      },
      rule: {
        eventCodeName: [
          { required: true, message: "请选择事件类型", trigger: "change" },
        ],
        eventAddress: [
          { required: true, message: "请选择地址", trigger: "change" },
        ],
        lineAttendant: [
          { required: true, message: "请选择巡线员", trigger: "change" },
        ],
        eventPlone: [
          {
            required: true,
            message: "请输入上报人电话",
            trigger: ["blur", "change"],
            // pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
          },
        ],
        deptId:[
          { required: true, message: "请选择受理部门",  trigger: ["blur", "change"], },
        ],
        priorLevel:[
          { required: true, message: "请选择优先级", trigger: "change" },
        ]
      },
      // 详细信息
      detiltable: {
        confirmedUrls: [],
      },
      // 巡检点状态
      sourceStatus: [
        { label: "是", id: "1" },
        { label: "否", id: "0" },
      ],
      // 事件状态
      userStatus: [
        { label: "待确认", id: "0" },
        { label: "已确认", id: "1" },
        { label: "待处理", id: "2" },
        { label: "已处理", id: "3" },
      ],
      // 时间
      DataPicker: [],
      // 列表数据
      tableData: [],
      // 事件类型
      eventTypeList: [],
      // 巡线员
      peopleList: [],
      // 维修人员
      maintainList: [],
      // 列表总数
      eventstotal: 0,
      // 事件提交维修
      texture: {
        priorLevel:'3',
        userId:''
      },
      // 事件类型
      eventTypeIdList: [],
      // 受理部门
      sectionList:[],
      // 优先级
      priorityList:[],
      // 受理人员
      maintainUserList:[],
      // 设为已处理弹窗
      showprocessed:false,
      processedform:{}
    };
  },
  mounted() {
    // 获取巡线员
    // zypatrolGetUserList({type:2}).then(res=>{
    //   this.peopleList = res.data
    // })
    // 获取维修员
    zypatrolGetUserList({ type: 1 }).then((res) => {
      this.maintainList = res.data;
      this.peopleList = res.data;
    });
    this.loadeventslist(this.paramsData);
    this.showEventTypeList();
  },
  methods: {
    /**
     * 关闭设为已处理弹窗
     */
    closeshowprocessed(){
      this.showprocessed = false
      if(this.$refs.uploadfile){
        this.$refs.uploadfile.setImgKeyList()
      }
    },
    /**
     * 设为已处理确认事件
     */
    submitprocessed(){
      this.getsetEvent()
    },
    /**
     * 斑马线
     */
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "warning-row";
      } else {
        return "success-row";
      }
    },
    /**
     * 查看工单
     * eventDataId 工单id
     * tplId 模板id
     */
    details(dataType, tplId, row) {
      // 设备工单
      if (dataType == 2) {
        // this.$router.push({
        //   path: "/home/devicePollingDesc",
        //   query: { id: tplId, row: JSON.stringify(row) },
        // });
        let chakan = this.$router.resolve({
					path: '/home/devicePollingDesc',      //跳转目标窗口的地址
					query:  { id: tplId, row: JSON.stringify(row) }
				})
				window.open(chakan.href);
      }
      // 事件工单
      if (dataType == 3) {
        // this.$router.push({
        //   path: "/home/eventPollingDesc",
        //   query: { id: row.tplId, row: JSON.stringify(row) },
        // });
        let chakan = this.$router.resolve({
					path: '/home/eventPollingDesc',      //跳转目标窗口的地址
					query: { id: row.tplId, row: JSON.stringify(row) }
				})
				window.open(chakan.href);
      }
    },
    /**
     * 添加的时候的选择类型
     */
    eventCodeSelect() {
      this.form.eventCodeId = this.form.eventCodeName[1];
    },
    /**
     * 操作更多功能
     */
    exchange(command, row) {
      if (command == "设置巡检点" || command == "取消巡检点") {
        this.del(row);
      }
      if (command == "转维修") {
        this.maintain(row);
      }
      if (command == "设为已处理") {
        // this.getsetEvent(row);
        // this.disposeItem = row
        this.showprocessed = true
        this.processedform = {}
        if(this.$refs.uploadfile){
          this.$refs.uploadfile.setImgKeyList()
        }
        if (this.$refs.processedform) {
          this.$refs.processedform.resetFields()
        }
        this.processedform.id = row.id
      }
    },
    /**
     * 添加
     */
    addGroup() {
      this.showAddModel = true;
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
    },
    /**
     * 添加事件的关闭
     */
    closeeventDialog(form) {
      this.form = {
        eventCodeName: [],
        eventAddress: "",
        lineAttendant:'',
        eventPlone:'',
        eventRemark:''
      };
      this.$refs[form].resetFields();
      // this.$refs.upload.clearFiles();
      this.$eventBus.$emit("coordinate");
    },
    /**
     * 获取事件类型
     */
    showEventTypeList() {
      dicByType({ parentCode: "GAS_PATROL_EVENT", level: 2 }).then((res) => {
        if (res.code === 200) {
          for (let i = 0; i < res.data.length; i++) {
            let el = res.data[i];
            if (!el.children) {
              res.data.splice(i, 1);
              i = i - 1;
            }
          }
          this.eventTypeList = res.data;
        }
      });
    },
    /**
     * 提交
     */
    submitAdd(form) {
      let that = this;
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.eventCodeId) {
            if (this.form.eventAddress) {
              that.saveForm();
            } else {
              this.$message.error("请选择地址");
            }
          } else {
            this.$message.error("请选择事件类型");
          }
        }
      });
    },
    /**
     * 点击维修
     */
    maintain(row) {
      markEvent(row.id).then(() => {
        setTimeout(() => {
          this.loadeventslist(this.paramsData);
        }, 500);
      });
      this.texture.relationId = row.id;
      this.texture.userId = ''
      this.showeventDialog = true;
      // 部门下拉
      selectSysDeptTreeList({deptListShow:'n'}).then((e)=>{
        let tree=e.data
        if(tree[0]){
          tree[0].disabled=true
        }
        // 特殊处理
        if(tree[0].children) {
          tree[0].children.forEach(el => {
            if(el.id == 8) {
              el.disabled=true
            }
          });
        }
        this.sectionList = tree
      })
      // 优先级
      getTimeoutList().then(res => {
        this.priorityList = res.data
      })
      if(this.$refs.texture) {
      this.$refs.texture.resetFields();
      }
    },
    /**
     * 选择部门
     */
    selectDepartment(val){
      console.log(val)
      // 通过部门id查此部门及其下所有部门用户（人员下拉）
      selectAllUsersByDeptId({deptId:val,code:'aj006'}).then(res => {
        console.log(res)
        this.maintainUserList = res.data
      })
    },
    /**
     * 提交维修
     */
    submitService() {
      // let row = this.texture;
      // let data = {
      //   platform: 3,
      //   orderModel: "事件",
      //   orderState: 1,
      //   orderId: row.eventCode,
      //   acceptBy: row.eventPeopleIds,
      // };
      // saveMaintain(data).then((res) => {
      //   if (res.code == 200) {
      //     this.$message.success("提交成功");
      //     this.showeventDialog = false;
      //     maintainEvent(row.id).then((res) => {});
      //     this.loadeventslist(this.paramsData);
      //   } else {
      //     this.$message.error(res.msg);
      //   }
      // });
      this.$refs.texture.validate((valid) => {
        if (valid) {
          eventMaintain(this.texture).then((res) => {
            if (res.code == 200) {
              this.$message.success("提交成功");
              this.showeventDialog = false;
              maintainEvent(this.texture.relationId)
              this.loadeventslist(this.paramsData);
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      })
    },
    /**
     * 设为已处理
     */
    getsetEvent(row) {
      let that = this;
      this.processedform.handleImgUrl = this.$refs.uploadfile.getImgKeyList().join(',')
      let data = {...this.processedform}
      this.$confirm(`是否将此事件设为已处理状态?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        setEvent(data).then((res) => {
          if (res.code == 200) {
            that.$message.success("设置成功");
            that.showprocessed = false
            if(this.$refs.uploadfile){
              this.$refs.uploadfile.setImgKeyList()
            }
            markEvent(data.id).then(() => {
              setTimeout(() => {
                that.loadeventslist(that.paramsData);
              }, 500);
            });
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    /**
     * 新增事件
     */
    saveForm() {
      let data = {...this.form}
      data.lineAttendant = [data.lineAttendant]
      saveevent(data).then((res) => {
        if (res.code === 200) {
          this.showAddModel = false;
          this.$message.success(res.msg);
          this.loadeventslist(this.paramsData);
        }
      });
    },
    /**
     * 地图地点选择
     */
    closeModel(address, lnglat) {
      if (!address && !lnglat) {
        this.showModel = false;
      } else {
        this.form.locations = lnglat;
        this.form.eventAddress = address;
        this.showModel = false;
        this.$forceUpdate();
      }
    },
    /**
     * 设置/取消巡检点
     */
    del(row) {
      markEvent(row.id);
      let arr = row.checkingPoint == 0 ? "设置巡检点" : "取消巡检点";
      let type = row.checkingPoint == 0 ? "1" : "0";
      this.$confirm(`是否${arr}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setcheckpoint({ id: row.id, setType: type }).then((res) => {
            this.$message({
              message: "设置成功",
              type: "success",
            });
            // this.paramsData.current = 1
            this.loadeventslist(this.paramsData);
          });
        })
        .catch(() => {
          this.loadeventslist(this.paramsData);
        });
    },
    /**
     * 详情
     */
    seeDetil(row) {
      markEvent(row.id).then(() => {
        //this.loadeventslist(this.paramsData);
      });
      if (row.imgUrl) {
        row.imgUrlArr = row.imgUrl.split(",");
      } else {
        row.imgUrlArr = [];
      }
      if (row.fileUrl) {
        row.fileUrlArr = row.fileUrl.split(",");
      } else {
        row.fileUrlArr = [];
      }
      this.detiltable = row;
      this.detilVisible = !this.detilVisible;
    },
    /**
     * 删除
     */
    delhandle(row) {
      markEvent(row.id);
      this.$confirm(`是否删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let arr = [];
          arr.push(row.id);
          deleteBatchevent({ ids: arr }).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              // this.paramsData.current = 1
              if(this.tableData.length == 1 && (this.paramsData.current != 1)) {
                this.paramsData.current -= 1 
              }
              this.loadeventslist(this.paramsData);
            } else {
              this.loadeventslist(this.paramsData);
              this.$message({
                message: "删除失败",
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.loadeventslist(this.paramsData);
        });
    },
    /**
     * 查询
     */
    search() {
      if (this.DataPicker && this.DataPicker.length > 0) {
        this.paramsData.startTime = this.DataPicker[0];
        this.paramsData.endTime = this.DataPicker[1];
      } else {
        this.paramsData.startTime = null;
        this.paramsData.endTime = null;
      }
      this.paramsData.current = 1;
      this.loadeventslist(this.paramsData);
    },
    /**
     * 重置
     */
    resize() {
      this.paramsData = {
        current: 1,
        size: 100,
        searchText: "",
        eventStatus: "",
        checkpoint: "",
      };
      this.eventTypeIdList = [];
      this.DataPicker = [];
      this.loadeventslist(this.paramsData);
    },
    /**
     * 事件类型 选择事件
     */
    deformation() {
      let text = [];
      this.eventTypeIdList.forEach((el) => {
        text.push(el[1]);
      });
      this.paramsData.eventCodeId = text.join(",");
      // this.search();
    },
    handleCurrentChange(val) {
      this.paramsData.current = val;
      this.loadeventslist(this.paramsData);
    },
    handleSizeChange(val) {
      this.paramsData.size = val;
      this.paramsData.current = 1;
      this.loadeventslist(this.paramsData);
    },
    /**
     * 获取列表数据
     */
    loadeventslist(obj) {
      eventPage(obj).then((res) => {
        this.tableData = res.data.records;
        this.eventstotal = res.data.total;
      });
    },
  },
};
</script>

<style scoped lang='less'>
.item {
  color: #169bd5;
}
.activeClass {
  background-color: #f56c6c;
  color: #ffffff;
  cursor: pointer;
  padding: 10px;
  margin-left: 20px;
}
::v-deep.el-form-item__label-wrap {
  margin-left: 0 !important;
}
.noactiveClass {
  padding: 10px;
  margin-left: 20px;
}
.contain {
  margin: 0;
  background-color: transparent;
}
// 搜索栏样式
.modalityTop {
  border: 1px solid #ecedf1;
  padding: 10px;
  border-radius: 5px;
  background: #ffffff;
  margin-bottom: 10px;
  // 面包屑
  // .crumbs{
  //   padding: 10px 15px 20px;
  //   span{
  //     color: #3069E1;
  //   }
  // }
}
// 搜索
.searchForm {
  display: flex;
  align-items: center;
  // display: flex;
  flex-wrap: wrap;
  //   justify-content: space-between;
  .el-form-item {
    margin-left: 10px;
    margin-bottom: 0px;
  }
}
// 表格样式
.modalityCenter {
  background: #ffffff;
  padding: 10px 20px;
  // 新增按钮
  // .addButton{
  //   margin-bottom: 10px;
  // }
}
.el-pagination {
  margin: 15px 0 5px;
}
/deep/.el-descriptions-item__label {
  color: #616161;
}
/deep/.el-descriptions-item__content {
  color: #000000;
  flex-flow: wrap;
}
</style>
