<template>
  <div>
    <el-upload
      ref="upload"
      action="#"
      :auto-upload="true"
      :http-request="repairBeforUploadFun"
      :list-type="fileData.listType ? fileData.listType : fileDataD.listType"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :before-upload="beforUploadImg"
      :on-change="beforHFhandleChangeImg"
      :file-list="repairBeforFilefileList"
      :accept="fileData.accept ? fileData.accept : fileDataD.accept"
      :limit="fileData.limit ? fileData.limit : fileDataD.limit"
      :on-exceed="msgLimit"
      :on-error="onerror"
    >
      <span
        v-if="
          (fileData.listType && fileData.listType == 'picture-card') ||
            (!fileData.listType && fileDataD.listType == 'picture-card')
        "
        ><i class="el-icon-plus"></i
      ></span>
      <el-button v-else size="small" type="primary">点击上传</el-button>
    </el-upload>
    <el-dialog
      title="查看图片"
      :visible.sync="dialogVisibleImg"
      :inline="true"
      :modal="false"
      width="80%"
      :close-on-click-modal="false"
      @close="close"
    >
      <div style="width: 100%; height: 60vh;overflow: auto;">
        <img :src="dialogImageUrl" style="max-width: 100%" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import upload from '@/apis/contractApi.js'
export default {
  name: 'UploadFile',
  components: {},
  props: {
    fileData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      repairBeforFileFormData: new FormData(),
      repairBeforFilefileList: [],
      dialogVisibleImg: false,
      dialogImageUrl: '',
      imgList: [], //上传的图片
      imgKes: [],
      fileDataD: {
        accept: '.jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP', //图片格式
        limit: 5, //上传文件最多数量
        listType: 'picture-card', //fileList  显示文件格式
        fileSize: 100 //文件大小
      }
    }
  },
  methods: {
    /*
     ****************************************************************************************************
     * 功能描述： 上传失败提示
     * 开发人： 黄丽玲
     * 开发时间： 2022-6-13
     ****************************************************************************************************
     */
    onerror() {
      this.$message.error('上传文件失败')
    },
    /*
     ****************************************************************************************************
     * 功能描述： 文件上传成功
     * 开发人： 黄丽玲
     * 开发时间： 2022-6-13
     ****************************************************************************************************
     */
    async repairBeforUploadFun(e) {
      this.repairBeforFileFormData = new FormData()
      this.repairBeforFileFormData.append('file', e.file)
      this.repairBeforFileFormData.append('modelName', '测试')

      await upload.uploadImg(this.repairBeforFileFormData).then(res => {
        this.imgList.push(res.data.url)
        this.imgKes.push(res.data.randomId)
      })
    },
    /*
     ****************************************************************************************************
     * 功能描述： 文件上传前判断文件大小
     * 开发人： 黄丽玲
     * 开发时间： 2022-6-13
     ****************************************************************************************************
     */
    beforUploadImg(file) {
      let fileSize = this.fileData.fileSize ? this.fileData.fileSize : this.fileDataD.fileSize
      const size = file.size / 1024 / 1024
      if (size > fileSize) {
        this.$message.error('大小必须小于' + fileSize + 'M')
        return false
      }
    },
    /*
     ****************************************************************************************************
     * 功能描述： 修改文件前
     * 开发人： 黄丽玲
     * 开发时间： 2022-6-13
     ****************************************************************************************************
     */
    beforHFhandleChangeImg(file, fileList) {
      this.repairBeforFilefileList = fileList
    },
    /*
     ****************************************************************************************************
     * 功能描述： 删除文件
     * 开发人： 黄丽玲
     * 开发时间： 2022-6-13
     ****************************************************************************************************
     */
    async handleRemove(file, fileList) {
      let that = this
      let index = this.getArrayIndex(this.repairBeforFilefileList, file)
      this.repairBeforFilefileList.splice(index, 1)
      that.imgList.splice(index, 1)
      that.imgKes.splice(index, 1)
      // await upload.deleteImg({ keys: that.imgKes[index] }).then(res => {})
    },
    /**
     *
     * @param {查找的文件} arr
     * @param {被查找的对象} obj
     */
    getArrayIndex(arr, obj) {
      var i = arr.length
      while (i--) {
        if (arr[i] === obj) {
          return i
        }
      }
      return -1
    },
    /*
     ****************************************************************************************************
     * 功能描述： 查看文件详情
     * 开发人： 黄丽玲
     * 开发时间： 2022-6-13
     ****************************************************************************************************
     */
    handlePictureCardPreview(file) {
      let fileType = this.fileData.listType ? this.fileData.listType : this.fileDataD.listType
      if (fileType == 'fileList') {
        let index = this.getArrayIndex(this.repairBeforFilefileList, file)
        const link = document.createElement('a') //创建下载a标签
        link.href = file.url || this.imgList[index]
        link.target = '_back'
        link.style.display = 'none' //默认隐藏元素
        document.body.appendChild(link) // body中添加元素
        link.click() // 执行点击事件
        document.body.removeChild(link) //下载完成后移除元素
      } else {
        this.dialogImageUrl = file.url
        this.dialogVisibleImg = true
      }
    },
    /*
     ****************************************************************************************************
     * 功能描述： 关闭文件显示详情
     * 开发人： 黄丽玲
     * 开发时间： 2022-6-13
     ****************************************************************************************************
     */
    close() {
      this.dialogImageUrl = ''
    },
    /*
     ****************************************************************************************************
     * 功能描述： 上传文件多少限制提示
     * 开发人： 黄丽玲
     * 开发时间： 2022-6-13
     ****************************************************************************************************
     */
    msgLimit(file, fileList) {
      let num = this.fileData.limit ? this.fileData.limit : this.fileDataD.limit
      this.$message.error('最多上传' + num + '张图片')
    },
    /*
     ****************************************************************************************************
     * 功能描述： 设置上传过的文件，编辑时回显已上传文件
     * 开发人： 黄丽玲
     * 开发时间： 2022-6-13
     * fileList：回显文件格式未数组
     ****************************************************************************************************
     */
    setImgList(fileList) {
      this.repairBeforFilefileList = []
      if (fileList && fileList.length > 0) {
        this.imgList = fileList

        fileList.forEach(el => {
          this.repairBeforFilefileList.push({ url: el })
        })
      } else {
        this.imgList = []
        this.repairBeforFilefileList = []
      }
    },
    setImgKeyList(fileList) {
      if (fileList && fileList.length > 0) {
        this.imgKes = fileList
      } else {
        this.imgList = []
        this.imgKes = []
        this.repairBeforFilefileList = []
      }
    },
    /*
     ****************************************************************************************************
     * 功能描述： 获取上传的文件，返回已上传的文件列表
     * 开发人： 黄丽玲
     * 开发时间： 2022-6-13
     ****************************************************************************************************
     */
    getImgList() {
      return this.imgList
    },
    getImgKeyList() {
      return this.imgKes
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-upload--picture-card {
  width: 90px;
  height: 90px;
  line-height: 90px;
  background: none;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 90px;
  height: 90px;
}
</style>